import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Navbar = ({ location }) => {
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    if (!active) {
      setActive(true)
      setNavBarActiveClass('is-active')
      return
    }
    setActive(false)
    setNavBarActiveClass('')
  }

  return (
    <>
      <div className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/">
              <StaticImage src="../img/bas-logo-full.png" alt="logo" width={100} />
            </Link>
            <div
              className={`navbar-burger ${navBarActiveClass}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbar"
              onClick={() => toggleHamburger()}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </div>
          </div>
          <div className={`navbar-menu ${navBarActiveClass}`} id="navbar">
            <div className="navbar-end">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {!active && <div className="has-text-right mt-4 is-size-5">natasha@buildsearchtech.com</div>}
                <div className="navbar-end is-uppercase">
                  <Link className="navbar-item mx-5" to="/">
                    Home
                  </Link>
                  <Link className={`navbar-item mx-5 ${location.pathname === '/about' ? 'is-active' : ''}`} to="/about">
                    About
                  </Link>
                  <Link className={`navbar-item mx-5 ${location.pathname === '/services' ? 'is-active' : ''}`} to="/services">
                    Services
                  </Link>
                  <Link className={`navbar-item mx-5 ${location.pathname === '/contact' ? 'is-active' : ''}`} to="/contact">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero is-primary" style={{ height: '2rem' }} />
    </>
  )
}

export default Navbar
