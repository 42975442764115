import React from 'react'
import { Link } from 'gatsby'

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import linkedin from '../img/social/linkedin.svg'
import twitter from '../img/social/twitter.svg'

import Navbar from './navbar'

import '../css/all-css.sass'

const Layout = ({ location, children }) => (
  <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Navbar location={location} />
    <main style={{ flexGrow: 1 }}>
      <div className="container content">{children}</div>
    </main>
    <footer className="footer has-text-primary">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="is-size-4">Contact</div>
            <div>natasha@buildsearchtech.com</div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <div className="is-size-4">Resources</div>
                <div>
                  <Link to="/blog">Blog</Link>
                </div>
              </div>
              <div className="column">
                <div className="is-size-4">Follow Us</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <a href="https://www.facebook.com/buildsearchtech">
                    <img src={facebook} alt="Facebook" width={32} style={{ filter: 'invert(1)', cursor: 'pointer' }} />
                  </a>
                  <a href="https://www.instagram.com/buildsearchtech/">
                    <img src={instagram} alt="Instagram" width={32} style={{ filter: 'invert(1)', cursor: 'pointer' }} />
                  </a>
                  <a href="https://twitter.com/build_search">
                    <img src={twitter} alt="Twitter" width={32} style={{ filter: 'invert(1)', cursor: 'pointer' }} />
                  </a>
                  <a href="https://www.linkedin.com/company/65364330/admin/">
                    <img src={linkedin} alt="Linked In" width={32} style={{ filter: 'invert(1)', cursor: 'pointer' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>Build and Search Technology LLC</div>
      </div>
    </footer>
  </div>
)

export default Layout
