import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const PageTemplate = ({ title, description, heading, subheading, featuredImage, body, location }) => {
  const image = getImage(featuredImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout location={location}>
      <SEO title={title} description={description} />
      <BackgroundImage className="hero is-large is-primary mt-4" {...bgImage} style={{}}>
        <div className="hero-body" style={{ backgroundColor: 'rgba(0,0,0,.3)' }}>
          <div className="container has-text-centered">
            <h1 className="title is-1" style={{ textTransform: 'uppercase' }}>
              {heading}
            </h1>
            <div className="subtitle" style={{ textTransform: 'lowercase' }}>
              {subheading}
            </div>
          </div>
        </div>
      </BackgroundImage>
      {body}
    </Layout>
  )
}

export const pageQuery = graphql`
  query Page($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        metaTitle
        description
        heading
        subheading
        featuredImage {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`

const Page = ({
  data: {
    mdx: {
      frontmatter: { metaTitle, description, heading, subheading, featuredImage },
      body,
    },
  },
  location,
}) => {
  const compiledBody = <MDXRenderer>{body}</MDXRenderer>

  return (
    <PageTemplate
      title={metaTitle}
      description={description}
      heading={heading}
      subheading={subheading}
      featuredImage={featuredImage}
      body={compiledBody}
      location={location}
    />
  )
}

export default Page
