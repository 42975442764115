import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description }) => (
  <Helmet
    title={title}
    meta={[
      { name: `description`, content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ]}
  />
)

export default SEO
